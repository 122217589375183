<template>
  <div class="tabsBox">
    <el-tabs :tab-position="tabPosition">
      <el-tab-pane label="Credit China">
        <div class="main-ability3">
          <div class="left-box">
            <div class="ability3-left">
              <div class="mask-1">
                <img src="../assets/img/信用中国/信用中国2.png" alt="" />
              </div>
              <div class="mask-2">
                <img src="../assets/img/信用中国/信用中国1.png" alt="" />
              </div>
            </div>
          </div>
          <div class="right-box">
            <h4 style="text-align: left; margin-top: 0">
              Interview with Credit China CCTV, Feng Chen:One-Stop Service For
              Automotive After-Sale Technology.
            </h4>
            <div class="right-desc">
              <div class="red-box">
                <span class="red-font">Original</span>
                <span class="hui-font">Credit China Column</span>
              </div>
              <div class="hui-time">2024/03/04</div>
            </div>
            <p class="font-desc">
              With the rapid evolution of the antomotive industry, consumers'
              demands for vehicle quality, performance,and services are
              continuously increasing. Against this backdrop, car manufacturers
              and dealerships must provide professional automotive technical
              services and innovative marketing strategies to maintain a
              competitve edge in the fierce market competition.Amidst this wave
              of change,Mr. Chen, With his profound industy experience an
              forward-looking vision,has led Wuhan ForCarTech Automotive
              Technology Co., Ltd. to the forefront of the industry.
            </p>
            <div class="bgc-hui">
              <div class="red-animation"></div>
            </div>
            <div class="red-href">
              <a
                href="https://book.cctv.com/2024/04/04/VIDEaYwcqBBh1tK7SfErECPK240404.shtml"
                target="_blank"
                >details →</a
              >
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Professional Honor">
        <div class="main-ability3">
          <div class="left-box" style="margin-top: -20px">
            <div class="ability3-left">
              <div class="mask-1">
                <img src="../assets/img/信用中国/聘书1.png" alt="" />
              </div>
            </div>
          </div>
          <div class="right-box">
            <h4 style="text-align: left; margin-top: -5px">
              Honor Conferred: Expert (Member) of the 2nd Automotive Repair
              Information Disclosure Expert Committee
            </h4>
            <div class="right-desc"></div>
            <p class="font-desc" style="margin-top: -15px; margin-bottom: 0">
              With years of professional accumulation and outstanding
              contributions in the industry, Comrade Chen Feng has been
              conferred the honor of "Expert (Member) of the 2nd Automotive
              Repair Information Disclosure Expert Committee" and officially
              appointed as a committee member for a three-year term. This honor
              not only reflects his professional status in the automotive repair
              field but also signifies his important role in promoting the
              transparency and standardization of automotive repair information.
              With his strong industry background and technical expertise, he
              will continue to make significant contributions in the next three
              years to drive industry development and improve repair service
              standards.
            </p>
            <div class="bgc-hui">
              <div class="red-animation"></div>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: "Ability3",
  tabPosition: "left",
};
</script>

<style scoped lang="scss">
::v-deep .el-tabs__item {
  color: red;
}
::v-deep .el-tabs__item.is-active {
  color: red;
  font-weight: bold;
}
::v-deep .el-tabs__item:hover {
  color: red;
}
::v-deep .el-tabs__active-bar {
  background-color: red;
}
.tabsBox {
  width: 1150px;
  height: 350px;
  margin: 0 auto;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    height: 850px;
  }
}
.main-ability3 {
  width: 1150px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-bottom: 5px;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }

  .ability3-left {
    position: relative;
    width: 500px;
    overflow: hidden;

    &:hover {
      .mask-1 {
        transform: scale(1.1);
        transition: 0.5s all;
      }
    }

    img {
      width: 100%;
      height: 100%;
      opacity: 0.9;
    }

    .mask-1 {
      transition: 0.5s all;
    }

    .mask-2 {
      position: absolute;
      top: 0;
      left: 0;
      height: 275px;

      @media (max-width: 768px) {
        height: 100%;
      }
    }

    @media (max-width: 768px) {
      width: 100%;

      .mask-1 {
        transform: none;
      }
    }
  }

  .right-box {
    width: 570px;
    position: relative;

    &:hover {
      .bgc-hui .red-animation {
        width: 100%;
      }
    }

    @media (max-width: 768px) {
      width: 100%;
      padding: 10px;
    }

    h3 {
      margin-top: 0;
      padding-top: 0;
      text-align: left;
      letter-spacing: 2px;
    }

    .right-desc {
      display: flex;
      justify-content: space-between;
      font-size: 16px;

      .red-font {
        border: 1px solid red;
        border-radius: 3px;
        color: red;
        font-weight: 700;
        margin-right: 10px;
        font-size: 12px;
        padding: 2px;
      }

      .hui-font {
        padding: 2px;
        background-color: #e7e7e7;
        font-size: 12px;
        color: #111111;
      }

      .hui-time {
        color: #bbbbbb;
        font-size: 16px;
        padding-right: 10px;
      }
    }

    .font-desc {
      color: #111111;
      letter-spacing: 1px;
      line-height: 23px;
      font-size: 14px;
      text-indent: 2em;
      text-align: left;
    }

    .bgc-hui {
      height: 3px;
      background-color: #e6e6e6;

      .red-animation {
        height: 3px;
        width: 100px;
        background-color: red;
        transition: width 1s ease;
      }

      @media (max-width: 768px) {
        display: none;
      }
    }

    .red-href {
      position: absolute;
      bottom: 5px;
      right: 0;

      a {
        color: red;
        text-decoration: none;
        font-weight: 700;
        font-size: 15px;

        @media (max-width: 768px) {
          font-size: 10px;
        }
      }

      @media (max-width: 768px) {
        bottom: -5px;
      }
    }
  }
}
</style>
